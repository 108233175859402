import styled from 'styled-components';
import { Input } from 'react-rainbow-components';
import { GREY_LIGHTER, BRAND, BLACK } from '../../constants';

export default styled(Input)`
    label {
        font-size: 0.9375rem;
        font-weight: 500;
        margin-left: 0;
        color: ${BLACK};
        display: flex;

        span:first-of-type {
            flex-shrink: 0;
        }

        > span {
                line-height: 1.467;
                font-size: 0.85rem;
                color: #6f6d7d;
                font-weight: 400;
                display: inline-block;
            }
    }

    input {
        border: 1px solid transparent;
        border-radius: 0.375rem;
        box-shadow: none;
        background-color: ${GREY_LIGHTER};
        line-height: 4rem;
        height: 4rem;
        padding: 0 1.2rem;
        font-weight: 500 !important;
        font-size: 1.125rem !important;
        letter-spacing: 0.25px !important;

        ::placeholder {
            font-weight: 400 !important;
            letter-spacing: 0.25px !important;
        }

        &:focus, &:active, &:visited {
            border: 1px solid ${BRAND} !important;
            line-height: 4rem;
            height: 4rem;
            padding: 0 1.2rem;
        }
    }
`;
