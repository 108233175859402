import { ReactNode, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UniversalPickerOption } from '@rainbow-modules/forms';
import TopBar from 'components/TopBar';
import LegalFooter from 'components/LegalFooter';
import ArrowNarrowRight from 'components/Icons/arrowNarrowRight';
import PageHeader from 'components/PageHeader';
import useRedirectWhenNoFormState from 'hooks/useRedirectWhenNoFormState';
import {
    Container,
    FormContainer,
    StyledButton,
    ButtonLabel,
    StyledPickerOptionContent,
    StyledOptionLabel,
    StyledRadioItem,
    StyledRadio,
    StyledUniversalPicker,
} from './styled';

interface RadioItemProps {
    children: ReactNode;
    isHover: boolean;
    isFocused: boolean;
    isSelected: boolean;
    disabled: boolean;
}

const RadioItem = ({ children, ...rest }: RadioItemProps) => (
    <StyledRadioItem {...rest}>
        <StyledRadio {...rest} />
        <div>{children}</div>
    </StyledRadioItem>
);

const AskLanguage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [language, setLanguage] = useState(state?.language || 'en');

    useRedirectWhenNoFormState();

    const handleNext = () => {
        navigate('/app/additional-information', {
            state: {
                ...state,
                language,
            },
            replace: true,
        });
    };

    const handlePrev = () => {
        navigate('/app/age', {
            state: {
                ...state,
                language,
            },
            replace: true,
        });
    };

    return (
        <Container>
            <TopBar onBack={handlePrev} />
            <FormContainer>
                <PageHeader
                    title={`What's ${state?.name}'s chat language?`}
                    description={`Pick the language for ${state?.name}'s enchanting conversation with Santa.`}
                />
                <StyledUniversalPicker
                    label="Child's Language"
                    multiple={false}
                    direction="vertical"
                    value={language}
                    onChange={(v) => setLanguage(v as string)}
                    labelAlignment="left"
                    required
                >
                    <UniversalPickerOption component={RadioItem} name="en">
                        <StyledPickerOptionContent>
                            <StyledOptionLabel>English (US) — for merry chats</StyledOptionLabel>
                        </StyledPickerOptionContent>
                    </UniversalPickerOption>

                    <UniversalPickerOption component={RadioItem} name="es">
                        <StyledPickerOptionContent>
                            <StyledOptionLabel>
                                Español (US) — para charlas alegres
                            </StyledOptionLabel>
                        </StyledPickerOptionContent>
                    </UniversalPickerOption>
                </StyledUniversalPicker>
                <StyledButton
                    shaded
                    borderRadius="semi-square"
                    size="large"
                    variant="brand"
                    onClick={handleNext}
                >
                    <ButtonLabel>
                        Next - Speak Magic
                    </ButtonLabel>
                    <ArrowNarrowRight />
                </StyledButton>
            </FormContainer>
            <LegalFooter />
        </Container>
    );
};

export default AskLanguage;
