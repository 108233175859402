import styled, { css } from 'styled-components';
import { ButtonIcon } from 'react-rainbow-components';
import { DARK_GREY } from '../../constants';

export default styled(ButtonIcon)`
    flex-shrink: 0;

    ${(props) => props.size === 'small' && css`
        width: 2rem;
        height: 2rem;

        svg {
            width: 1.35rem !important;
            height: 1.35rem !important;
            font-size: 1.35rem !important;
        }
    `}


    ${(props) => props.variant === 'base' && css`
        color: ${DARK_GREY};
    `}


    ${(props) => props.borderRadius === 'semi-square' && css`
        border-radius: 0.375rem;
    `}
`;
