import { useEffect, useRef, useState } from 'react';
import { FirebaseError } from 'firebase/app';
import { signInWithPhoneNumber, RecaptchaVerifier, ConfirmationResult } from 'firebase/auth';
import { RenderIf } from 'react-rainbow-components';
import { showAppMessage, hideAppMessage } from '@rainbow-modules/app';
import useCreateCustomer from 'data/hooks/useCreateCustomer';
import getFirebaseErrorMessage from 'data/helpers/getFirebaseErrorMessage';
import TopBar from 'components/TopBar';
import Footer from 'components/Footer';
import ArrowNarrowRight from 'components/Icons/arrowNarrowRight';
import PageHeader from 'components/PageHeader';
import CodeInput from 'components/CodeInput';
import { auth } from '../../firebase';
import {
    Container,
    FormContainer,
    StyledPhoneInput,
    StyledButton,
    ButtonLabel,
    PhoneNumber,
    TopContent,
    TermsOfServiceContainer,
} from './styled';

const Login = () => {
    const recaptchaVerifier = useRef<any>(null);
    const codeInputRef = useRef(null);
    const [phoneNumber, setPhoneNumber] = useState<any>('');
    const [confirmationResult, setConfirmation] = useState<ConfirmationResult | null>(null);
    const [code, setCode] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const { mutateAsync: createCustomer } = useCreateCustomer();

    const onNext = async () => {
        setIsLoading(true);
        try {
            const results = await signInWithPhoneNumber(
                auth,
                `${phoneNumber.countryCode}${phoneNumber.phone}`,
                recaptchaVerifier.current,
            );
            hideAppMessage();
            setConfirmation(results);
        } catch (error) {
            showAppMessage({
                message: getFirebaseErrorMessage((error as FirebaseError).code),
                variant: 'error',
            });
        }
        setIsLoading(false);
    };

    const onLogin = async () => {
        setIsLoading(true);
        let user;
        try {
            const result = await confirmationResult?.confirm(code);
            user = result?.user;
            hideAppMessage();
        } catch (error) {
            showAppMessage({
                message: getFirebaseErrorMessage((error as FirebaseError).code),
                variant: 'error',
            });
        }
        if (user) {
            try {
                await createCustomer({
                    body: {
                        phoneNumber: user.phoneNumber,
                    },
                });
            } catch (error) {
                //
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (confirmationResult === null) {
            recaptchaVerifier.current = new RecaptchaVerifier('sign-in-button', {
                size: 'invisible',
                callback: async () => {
                },
            }, auth);
        }
    }, [confirmationResult]);

    useEffect(() => {
        if (confirmationResult && codeInputRef.current) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            codeInputRef.current?.focus();
        }
    }, [confirmationResult]);

    return (
        <Container>
            <TopBar hideBackButton={!confirmationResult} onBack={() => setConfirmation(null)} />
            <FormContainer>
                <RenderIf isTrue={!confirmationResult}>
                    <PageHeader
                        title="Ready to Ring Santa? 🎅🏻"
                        description="Enter your phone number below to secure your direct line to the North Pole!"
                    />
                    <StyledPhoneInput
                        value={phoneNumber}
                        label="Phone Number"
                        placeholder="Enter your phone number"
                        onChange={setPhoneNumber}
                        borderRadius="semi-square"
                        countries={['us']}
                        labelAlignment="left"
                        required
                    />
                    <div>
                        <StyledButton
                            shaded
                            borderRadius="semi-square"
                            size="large"
                            variant="brand"
                            id="sign-in-button"
                            onClick={onNext}
                            isLoading={isLoading}
                            disabled={!phoneNumber || !phoneNumber.phone}
                        >
                            <ButtonLabel>
                                Next
                            </ButtonLabel>
                            <ArrowNarrowRight />
                        </StyledButton>
                        <TermsOfServiceContainer>
                            <div>
                                By signing up, you have read and agree to our
                                {' '}
                                <a href="https://www.voxity.ai/santa-ai-terms-of-service">terms of service</a>
                                {' '}
                                and
                                {' '}
                                <a href="https://www.voxity.ai/santa-ai-privacy-policy">privacy policy</a>
                            </div>
                        </TermsOfServiceContainer>
                    </div>
                </RenderIf>
                <RenderIf isTrue={confirmationResult}>
                    <TopContent>
                        <PageHeader
                            title="Santa's Waiting! 🎅🏻"
                            description="Ho-Ho-Hold on! Please enter the 6-digit magic code sent to your phone to continue."
                        />
                        <PhoneNumber>
                            {`*** *** ${phoneNumber?.phone?.slice(-4)}`}
                        </PhoneNumber>
                    </TopContent>
                    <CodeInput
                        label="Type your Verification Code"
                        labelAlignment="left"
                        value={code}
                        length={6}
                        onChange={setCode}
                        required
                        ref={codeInputRef}
                    />
                    <StyledButton
                        shaded
                        label="Verify"
                        borderRadius="semi-square"
                        size="large"
                        variant="brand"
                        onClick={onLogin}
                        isLoading={isLoading}
                        disabled={!code}
                    />
                </RenderIf>
            </FormContainer>
            <Footer />
        </Container>
    );
};

export default Login;
