import { StyledFooter, StyledLink } from './styled';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <StyledFooter>
            <div>
                ©
                {' '}
                {currentYear}
                , Made with ❤️ by
                <StyledLink href="https://www.voxity.ai/" target="_blank" rel="noreferrer">
                    Voxity
                </StyledLink>
            </div>
        </StyledFooter>
    );
};

export default Footer;
