import { useLayoutEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useChildrenCount from 'data/firestore/user/child/useCount';
import { auth } from 'data/firestore/firebase';
import TopBar from '../../components/TopBar';
import Footer from '../../components/Footer';
import ArrowNarrowRight from '../../components/Icons/arrowNarrowRight';
import PageHeader from '../../components/PageHeader';
import {
    Container, StyledButton, ButtonLabel, Content, ImageContainer, Image, BorderImage,
} from './styled';

const Landing = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const user = auth.currentUser;
    const { count, isLoading } = useChildrenCount(user?.uid as string);

    useLayoutEffect(() => {
        if (count) {
            navigate('/app/pick-child', { replace: true });
        }
    }, [count, navigate]);

    return (
        <Container>
            <TopBar hideBackButton />
            <Content>
                {!isLoading && count !== null && (
                    <>
                        <ImageContainer>
                            <BorderImage>
                                <Image />
                            </BorderImage>
                        </ImageContainer>
                        <PageHeader
                            title="Santa is Ready to Meet Your Star! 🌟"
                            description="Santa can't wait to chat! Just a few festive details and we'll craft a magical moment for your child. It's as simple as hanging stockings with care!"
                        />
                        <StyledButton
                            shaded
                            borderRadius="semi-square"
                            size="large"
                            variant="brand"
                            onClick={() => navigate('/app/name', { state, replace: true })}
                        >
                            <ButtonLabel>
                                So, Start!
                            </ButtonLabel>
                            <ArrowNarrowRight />
                        </StyledButton>
                    </>
                )}
            </Content>
            <Footer />
        </Container>
    );
};

export default Landing;
