import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const useRedirectWhenNoFormState = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    useEffect(() => {
        if (!state) {
            navigate('/', { replace: true });
        }
    }, [state, navigate]);
};

export default useRedirectWhenNoFormState;
