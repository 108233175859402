import { ReactNode, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UniversalPickerOption } from '@rainbow-modules/forms';
import TopBar from 'components/TopBar';
import LegalFooter from 'components/LegalFooter';
import ArrowNarrowRight from 'components/Icons/arrowNarrowRight';
import PageHeader from 'components/PageHeader';
import useRedirectWhenNoFormState from 'hooks/useRedirectWhenNoFormState';
import {
    Container,
    FormContainer,
    StyledButton,
    ButtonLabel,
    StyledPickerOptionContent,
    StyledOptionLabel,
    StyledRadioItem,
    StyledRadio,
    StyledUniversalPicker,
} from './styled';

interface RadioItemProps {
    children: ReactNode;
    isHover: boolean;
    isFocused: boolean;
    isSelected: boolean;
    disabled: boolean;
}

const RadioItem = ({ children, ...rest }: RadioItemProps) => (
    <StyledRadioItem {...rest}>
        <StyledRadio {...rest} />
        <div>{children}</div>
    </StyledRadioItem>
);

const AskAge = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [age, setAge] = useState(state?.age || '2-8');

    useRedirectWhenNoFormState();

    const handleNext = () => {
        navigate('/app/language', {
            state: {
                ...state,
                age,
            },
            replace: true,
        });
    };

    const handlePrev = () => {
        navigate('/app/gender', {
            state: {
                ...state,
                age,
            },
            replace: true,
        });
    };

    return (
        <Container>
            <TopBar onBack={handlePrev} />
            <FormContainer>
                <PageHeader
                    title={`How many candles on ${state?.name}'s cake?`}
                    description={`Select the age range that ${state?.name} will be celebrating this year. Santa's got a special message for every age!`}
                />
                <StyledUniversalPicker
                    multiple={false}
                    direction="vertical"
                    value={age}
                    onChange={(v) => setAge(v as string)}
                    label="Child's Age"
                    labelAlignment="left"
                    required
                >
                    <UniversalPickerOption component={RadioItem} name="2-8">
                        <StyledPickerOptionContent>
                            <StyledOptionLabel>2-8 years</StyledOptionLabel>
                        </StyledPickerOptionContent>
                    </UniversalPickerOption>

                    <UniversalPickerOption component={RadioItem} name="8-12">
                        <StyledPickerOptionContent>
                            <StyledOptionLabel>8-12 years</StyledOptionLabel>
                        </StyledPickerOptionContent>
                    </UniversalPickerOption>

                    <UniversalPickerOption component={RadioItem} name="12-18">
                        <StyledPickerOptionContent>
                            <StyledOptionLabel>12-18 years</StyledOptionLabel>
                        </StyledPickerOptionContent>
                    </UniversalPickerOption>

                    <UniversalPickerOption component={RadioItem} name="more-than-18">
                        <StyledPickerOptionContent>
                            <StyledOptionLabel>Older than 18 years</StyledOptionLabel>
                        </StyledPickerOptionContent>
                    </UniversalPickerOption>
                </StyledUniversalPicker>
                <StyledButton
                    shaded
                    borderRadius="semi-square"
                    size="large"
                    variant="brand"
                    onClick={handleNext}
                >
                    <ButtonLabel>
                        Next - Special Wishes
                    </ButtonLabel>
                    <ArrowNarrowRight />
                </StyledButton>
            </FormContainer>
            <LegalFooter />
        </Container>
    );
};

export default AskAge;
