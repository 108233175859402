import styled, { css } from 'styled-components';
import { UniversalPicker } from '@rainbow-modules/forms';
import { LinkButton } from '@rainbow-modules/misc';
import { Spinner } from 'react-rainbow-components';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { BLACK, BRAND } from '../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #fff;
    min-height: 100vh;

    @media (max-width: 800px) {
        height: 100%;
        min-height: 100%;
    }
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 460px;
    width: 100%;
    padding: 1rem 2rem 2.5rem 2rem;
    gap: 24px;
    flex-grow: 1;
`;

export const StyledInput = styled(Input)`
    width: 100%;
`;

export const StyledLinkButton = styled(LinkButton)`
    width: 100%;
    margin-top: 1rem;
    border-radius: 0.375rem;
    letter-spacing: 0.02688rem;
    font-size: 1.0625rem;
    font-weight: 500;
    line-height: 3.2rem;
    box-shadow: 0px 2px 8px 0px rgba(225, 33, 35, 0.30);

    &[disabled] {
        background: ${BRAND};
        box-shadow: none;
    }

    svg {
        width: 1.8rem !important;
        height: 1.8rem !important;;
    }
`;

export const StyledButton = styled(Button)`
    width: 100%;
    margin-top: 1rem;
`;

export const ButtonLabel = styled.span`
    width: 100%;
    padding-right: 1.4rem;
`;

export const TopContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

// Picker Styles

interface StyledRadioItemProps {
    isHover: boolean;
    isFocused: boolean;
    isSelected: boolean;
    disabled: boolean;
}

export const StyledUniversalPicker = styled(UniversalPicker)`
    width: 100%;

    > div {
        gap: 0.5rem;

        > div {
            margin: 0;
        }
    }
`;

export const StyledRadioItem = styled.span<StyledRadioItemProps>`
    position: relative;
    width: 100%;
    padding: 1rem;
    background-color: white;
    border: solid 1px #dbdade;
    border-radius: 0.375rem;
    display: flex;

    ${(props) => props.isHover
        && `
            background-color: rgba(225, 33, 35, 0.05);    
            border: solid 1px ${BRAND};
            cursor: pointer;
    `};

    ${(props) => props.isFocused
        && `
            border: solid 1px ${BRAND};
            box-shadow: rgb(225, 33, 35) 0px 0px 2px;
    `};

    ${(props) => props.isSelected
        && `
            border: solid 1px ${BRAND};
            box-shadow: rgb(225, 33, 35) 0px 0px 2px;
    `};

    ${(props) => props.disabled
        && `
            border: solid 1px #dbdade;
            background-color: #dbdade;
            box-shadow: 0 0 0 0 transparent;
            cursor: not-allowed;
    `};
`;

export const StyledRadio = styled.span<StyledRadioItemProps>`
    position: relative;
    width: 20px;
    height: 20px;
    padding: 0;
    background: #fff;
    border: solid 2px #dbdade;
    border-radius: 20px;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;

    &::after {
        content: '';
        position: absolute;
        height: 8px;
        width: 8px;
        top: 4px;
        left: 4px;
        background: #fff;
        border-radius: 20px;
        box-sizing: border-box;
    }

    ${(props) => props.isSelected && css`
            background: ${BRAND};
            border: 2px solid;
            border-color: ${BRAND};
            box-shadow: 0px 2px 4px 0px rgba(165, 163, 174, 0.30);

            &::after {
                background: #fff;
                box-sizing: border-box;
            }
    `};

    ${(props) => props.isFocused && css`
            background: ${BRAND};
            border: 2px solid;
            border-color: ${BRAND};
            box-shadow: 0px 2px 4px 0px rgba(165, 163, 174, 0.30);
    `};

    ${(props) => props.disabled && css`
            background-color: #fff;
            border-color: #dbdade;

            &::after {
                background: #fff;
                box-sizing: border-box;
            }
    `};
`;

export const StyledPickerOptionContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-left: 0.85rem;
`;

export const StyledOptionLabel = styled.span`
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.3125rem;
    color: ${BLACK};
`;

export const StyledOptionDescription = styled.span`
    font-size: 0.85rem;
    font-weight: 300;
    line-height: 1.25rem;
    color: ${BLACK};
`;

export const AddChildButton = styled.button`
    width: 100%;
    padding: 1rem;
    background-color: white;
    border: solid 1px #dbdade;
    border-radius: 0.375rem;
    display: flex;
    color: ${BLACK};
    gap: 0.75rem;
`;

export const StyledContent = styled.span`
    visibility: hidden;
`;

export const StyledSpinner = styled(Spinner)`
    visibility: visible;
`;
