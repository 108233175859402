import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import addChild from 'data/firestore/user/child/add';
import { auth } from 'data/firestore/firebase';
import TopBar from 'components/TopBar';
import LegalFooter from 'components/LegalFooter';
import ArrowNarrowRight from 'components/Icons/arrowNarrowRight';
import PageHeader from 'components/PageHeader';
import useRedirectWhenNoFormState from 'hooks/useRedirectWhenNoFormState';
import {
    Container, FormContainer, StyledTextarea, StyledButton, ButtonLabel,
} from './styled';
// import addChild from 'data/firestore/user/tmpchild/add';

const AskAdditionalInformation = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [additionalInfo, setAdditionalInfo] = useState(state?.additionalInfo || '');
    const [isLoading, setIsLoading] = useState(false);

    useRedirectWhenNoFormState();

    const handleNext = async () => {
        const child = {
            ...state,
            additionalInfo,
        };
        const user = auth.currentUser;
        if (!user) {
            // TODO: handle
            return;
        }
        try {
            setIsLoading(true);
            await addChild(user.uid, child);
            navigate('/app/pick-child', { replace: true });
        } catch (error) {
            //
        }
        setIsLoading(false);
    };

    const handlePrev = () => {
        navigate('/app/language', {
            state: {
                ...state,
                additionalInfo,
            },
            replace: true,
        });
    };

    return (
        <Container>
            <TopBar onBack={handlePrev} />
            <FormContainer>
                <PageHeader
                    title="Secrets for Santa: What should he know? 🎄"
                    description={`Jot down the little whispers that'll make ${state?.name}'s chat with Santa extra sparkly.`}
                />
                <StyledTextarea
                    label="Additional Information (optional)"
                    placeholder="Type your secrets here... Santa loves little surprises!"
                    borderRadius="semi-square"
                    rows={5}
                    labelAlignment="left"
                    value={additionalInfo}
                    onChange={(e) => setAdditionalInfo(e.target.value.replace(/[^A-Za-zñáéíóúÑÁÉÍÓÚüÜ., ]/g, ''))}
                    grow
                    maxLength={300}
                />
                <StyledButton
                    shaded
                    borderRadius="semi-square"
                    size="large"
                    variant="brand"
                    onClick={handleNext}
                    isLoading={isLoading}
                >
                    <ButtonLabel>
                        {'Next - Let\'s Sparkle!'}
                    </ButtonLabel>
                    <ArrowNarrowRight />
                </StyledButton>
            </FormContainer>
            <LegalFooter />
        </Container>
    );
};

export default AskAdditionalInformation;
