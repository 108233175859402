import styled, { css } from 'styled-components';
import AppName from 'components/Icons/appName';

interface Props {
    hideBackButton?: boolean;
}

export const Container = styled.div<Props>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 2rem 4rem 1.5rem 1.5rem;

    ${(props) => props.hideBackButton && css`
        padding: 2rem 1.5rem 1.5rem 1.5rem;
    `}
`;

export const Name = styled.span`
    flex-grow: 1;
    text-align: center;
`;

export const StyledAppName = styled(AppName)`
    height: 40px;
    width: auto;
`;
