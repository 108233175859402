import { IconProps } from 'types';

const Phone = ({ title = 'File Text', className }: IconProps) => (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none">
        <title>{title}</title>

        <path
            d="M4.16667 3.33325H7.5L9.16667 7.49992L7.08333 8.74992C7.9758 10.5595 9.44039 12.0241 11.25 12.9166L12.5 10.8333L16.6667 12.4999V15.8333C16.6667 16.7537 15.9205 17.4999 15 17.4999C8.27304 17.0911 2.9088 11.7269 2.5 4.99992C2.5 4.07944 3.24619 3.33325 4.16667 3.33325"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default Phone;
