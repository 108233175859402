import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { AddService, EntityGet } from "../../types";
import { Child } from "./types";
import get from "./get";

export type ChildAddService<T> = (
  userId: string,
  doc: Omit<T, "id">
) => Promise<EntityGet<T>>;

const add: ChildAddService<Child> = async (userId, data) => {
  const collectionRef = collection(db, `/user/${userId}/child`);
  const now = serverTimestamp();
  const docRef = await addDoc(collectionRef, {
    ...data,
    createdAt: now,
    updatedAt: now,
  });
  return get(userId, docRef.id) as Promise<EntityGet<Child>>;
};

export default add;
