import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase';

interface Params {
    [key: string]: unknown
}

export default (name: string, params?: Params) => logEvent(
    analytics,
    name,
    params,
);
