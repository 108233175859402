import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Input from 'components/Input';
import TopBar from 'components/TopBar';
import LegalFooter from 'components/LegalFooter';
import ArrowNarrowRight from 'components/Icons/arrowNarrowRight';
import PageHeader from 'components/PageHeader';
import {
    Container, FormContainer, StyledInput, StyledButton, ButtonLabel,
} from './styled';

const AskName = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [name, setName] = useState(state?.name || '');
    const [consent, setConsent] = useState(state?.consent || false);
    const [error, setError] = useState('');

    const handleNext = () => {
        if (consent) {
            return navigate('/app/gender', {
                state: {
                    ...state,
                    name,
                    consent,
                },
                replace: true,
            });
        }
        return setError('Please confirm your consent as a parent or guardian to proceed.');
    };

    const handlePrev = () => {
        navigate('/app', {
            state: {
                ...state,
                name,
                consent,
            },
            replace: true,
        });
    };

    return (
        <Container>
            <TopBar onBack={handlePrev} />
            <FormContainer>
                <PageHeader
                    title="What's the name of the lucky child? 👶"
                    description="Tell us your little child's name so Santa can add a personal touch to their holiday cheer!"
                />
                <StyledInput
                    label="Chlid's Name"
                    placeholder="Little child's name here..."
                    borderRadius="semi-square"
                    value={name}
                    onChange={(e) => setName(e.target.value.replace(/[^A-Za-zñáéíóúÑÁÉÍÓÚüÜ ]/g, ''))}
                    labelAlignment="left"
                    required
                />
                <div>
                    <StyledButton
                        shaded
                        borderRadius="semi-square"
                        size="large"
                        variant="brand"
                        onClick={handleNext}
                        disabled={!name}
                    >
                        <ButtonLabel>
                            {'Next - Let\'s Sparkle!'}
                        </ButtonLabel>
                        <ArrowNarrowRight />
                    </StyledButton>
                    <Input
                        type="checkbox"
                        label="I am the parent/legal guardian and I give my consent for my child’s data to be collected and used for the purpose of the Santa AI's call."
                        onChange={(event) => {
                            setConsent(event.target.checked);
                            setError('');
                        }}
                        checked={consent}
                        error={error}
                    />
                </div>
            </FormContainer>
            <LegalFooter />
        </Container>
    );
};

export default AskName;
