import { ReactNode, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UniversalPickerOption } from '@rainbow-modules/forms';
import TopBar from 'components/TopBar';
import LegalFooter from 'components/LegalFooter';
import ArrowNarrowRight from 'components/Icons/arrowNarrowRight';
import PageHeader from 'components/PageHeader';
import useRedirectWhenNoFormState from 'hooks/useRedirectWhenNoFormState';
import {
    Container,
    FormContainer,
    StyledButton,
    ButtonLabel,
    StyledPickerOptionContent,
    StyledOptionLabel,
    StyledRadioItem,
    StyledRadio,
    StyledUniversalPicker,
} from './styled';

interface RadioItemProps {
    children: ReactNode;
    isHover: boolean;
    isFocused: boolean;
    isSelected: boolean;
    disabled: boolean;
}

const RadioItem = ({ children, ...rest }: RadioItemProps) => (
    <StyledRadioItem {...rest}>
        <StyledRadio {...rest} />
        <div>{children}</div>
    </StyledRadioItem>
);

const AskGender = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [gender, setGender] = useState(state?.gender || 'girl');

    useRedirectWhenNoFormState();

    const handleNext = () => {
        navigate('/app/age', {
            state: {
                ...state,
                gender,
            },
            replace: true,
        });
    };

    const handlePrev = () => {
        navigate('/app/name', {
            state: {
                ...state,
                gender,
            },
            replace: true,
        });
    };

    return (
        <Container>
            <TopBar onBack={handlePrev} />
            <FormContainer>
                <PageHeader
                    title={`What gift tag suits ${state?.name} best?`}
                    description="Help Santa personalize your child's holiday experience. Please select your child's gender."
                />
                <StyledUniversalPicker
                    label="Child's Gender"
                    labelAlignment="left"
                    required
                    multiple={false}
                    direction="vertical"
                    value={gender}
                    onChange={(v) => setGender(v as string)}
                >
                    <UniversalPickerOption component={RadioItem} name="girl">
                        <StyledPickerOptionContent>
                            <StyledOptionLabel>Girl</StyledOptionLabel>
                        </StyledPickerOptionContent>
                    </UniversalPickerOption>

                    <UniversalPickerOption component={RadioItem} name="boy">
                        <StyledPickerOptionContent>
                            <StyledOptionLabel>Boy</StyledOptionLabel>
                        </StyledPickerOptionContent>
                    </UniversalPickerOption>

                    <UniversalPickerOption component={RadioItem} name="none">
                        <StyledPickerOptionContent>
                            <StyledOptionLabel>Prefer not to say</StyledOptionLabel>
                        </StyledPickerOptionContent>
                    </UniversalPickerOption>
                </StyledUniversalPicker>
                <StyledButton
                    shaded
                    borderRadius="semi-square"
                    size="large"
                    variant="brand"
                    onClick={handleNext}
                >
                    <ButtonLabel>
                        Continue to Cheer!
                    </ButtonLabel>
                    <ArrowNarrowRight />
                </StyledButton>
            </FormContainer>
            <LegalFooter />
        </Container>
    );
};

export default AskGender;
