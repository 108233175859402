import styled from 'styled-components';
import { PhoneInput } from 'react-rainbow-components';
import { GREY_LIGHTER, BLACK } from '../../constants';

export default styled(PhoneInput)`
    font-size: 1.0625rem;
    font-weight: 600;
    line-height: 2.75rem;

    label {
        font-size: 0.9375rem;
        font-weight: 500;
        margin-left: 0;
        color: ${BLACK};
    }

    label + div {
        border: 1px solid transparent;
        border-radius: 0.375rem;
        box-shadow: none;
        background-color: ${GREY_LIGHTER};
        line-height: 4rem;
        height: 4rem;
        padding: 0 0.5rem;
    }

    button + div {
        line-height: 4rem;
        font-size: 1.25rem !important;
        font-weight: 400 !important;
    }

    button > div {
        border-right: 1px solid transparent;
        padding-right: 0;
    }

    input {
        font-weight: 500 !important;
        font-size: 1.25rem !important;
        letter-spacing: 1.5px !important;

        ::placeholder {
            font-weight: 400 !important;
            letter-spacing: 0.25px !important;
        }
    }

`;
