import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { UniversalPickerOption } from '@rainbow-modules/forms';
import TopBar from '../../components/TopBar';
import LegalFooter from '../../components/LegalFooter';
import PageHeader from '../../components/PageHeader';
import {
    Container,
    FormContainer,
    StyledButton,
    StyledPickerOptionContent,
    StyledOptionLabel,
    StyledOptionDescription,
    StyledUniversalPicker,
    StyledRadioItem,
    StyledRadio,
    OptionHeader,
    Message,
} from './styled';

interface RadioItemProps {
    children: ReactNode;
    isHover: boolean;
    isFocused: boolean;
    isSelected: boolean;
    disabled: boolean;
}

const RadioItem = ({ children, ...rest }: RadioItemProps) => (
    <StyledRadioItem {...rest}>
        <StyledRadio {...rest} />
        <div>{children}</div>
    </StyledRadioItem>
);

const Checkout = () => {
    const navigate = useNavigate();
    return (
        <Container>
            <TopBar />
            <FormContainer>
                <PageHeader
                    title="Keep the Magic Alive! ❄️"
                    description="A small gift keeps Santa's calls magical and supports St. Jude's mission."
                />
                <StyledUniversalPicker
                    multiple={false}
                    direction="vertical"
                >
                    <UniversalPickerOption component={RadioItem} name="child-1">
                        <StyledPickerOptionContent>
                            <OptionHeader>
                                <StyledOptionLabel>$4.99</StyledOptionLabel>
                                <StyledOptionDescription>/ by child</StyledOptionDescription>
                            </OptionHeader>
                            <StyledOptionDescription>
                                A single gift covers unlimited calls.
                            </StyledOptionDescription>
                        </StyledPickerOptionContent>
                    </UniversalPickerOption>
                </StyledUniversalPicker>
                <div>
                    <StyledButton
                        shaded
                        borderRadius="semi-square"
                        size="large"
                        variant="brand"
                        label="Pay"
                        onClick={() => navigate('/app/pick-child')}
                    />
                    <Message>100% of proceeds beyond service costs will go to St. Jude.</Message>
                </div>
            </FormContainer>
            <LegalFooter />
        </Container>
    );
};

export default Checkout;
