import styled from 'styled-components';
import PhoneInput from '../../components/PhoneInput';
import Button from '../../components/Button';
import { BLACK, DARK_GREY } from '../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #fff;
    min-height: 100vh;

    @media (max-width: 800px) {
        height: 100%;
        min-height: 100%;
    }
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 460px;
    width: 100%;
    padding: 2.5rem 2rem;
    gap: 40px;
    flex-grow: 1;
`;

export const StyledPhoneInput = styled(PhoneInput)`
    width: 100%;
`;

export const StyledButton = styled(Button)`
    width: 100%;

    // TODO: Without this it shows a red shadow when disabled. This is a bug in react-rainbow. Now it is a hack to remove the box shadow from the button when it's disabled.
    &[disabled] {
        box-shadow: none; !important;
    }
`;

export const ButtonLabel = styled.span`
    width: 100%;
    padding-left: 1.4rem;
`;

export const TopContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

export const PhoneNumber = styled.h3`
    font-size: 0.9375rem;
    font-weight: 700;
    line-height: 1.3125rem;
    color: ${BLACK};
`;

export const Disclaimer = styled.p`
    display: flex;
`;

export const TermsOfServiceContainer = styled.div`
    display: flex;
    gap: 0.5rem;
    color: ${DARK_GREY};
    font-size: 0.85rem;
    font-weight: 300;
    line-height: 1.375rem;
    padding: 1rem 0.5rem;

    a {
        color: ${DARK_GREY};
        border-bottom: 1px solid ${DARK_GREY};

        &:hover {
            color: ${BLACK};
            border-bottom: 1px solid ${BLACK};
            text-decoration: none;

        }
    }
`;
