import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCXhJ_RPDf7xnCfarJkpiMH82Rd7hW6lEA",
  authDomain: "santa-ai-7177c.firebaseapp.com",
  projectId: "santa-ai-7177c",
  storageBucket: "santa-ai-7177c.appspot.com",
  messagingSenderId: "750266894413",
  appId: "1:750266894413:web:32530d3db2c95c360d3404",
  measurementId: "G-TFJ80PN0JY",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
