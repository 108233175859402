import { doc, getDoc, getDocFromServer } from "firebase/firestore";
import { db } from "../../firebase";
import { GetServiceOpts, EntityGet, EntityServerGet } from "../../types";
import { Child } from "./types";
import { transformMetadata, processTimestampFields } from "../../utils";

export type ChildGetService<T> = (
  userId: string,
  id: string,
  opts?: GetServiceOpts
) => Promise<T | null>;

const get: ChildGetService<EntityGet<Child>> = async (
  userId,
  id,
  opts = {}
) => {
  const docRef = doc(db, `/user/${userId}/child/${id}`);
  const { disableCache } = opts;
  const getFn = disableCache ? getDocFromServer : getDoc;
  const docSnap = await getFn(docRef);
  if (docSnap.exists()) {
    return processTimestampFields(
      transformMetadata({
        ...docSnap.data(),
        id: docSnap.id,
      } as EntityServerGet<Child>)
    );
  }
  return null;
};

export default get;
