import {
    Routes,
    Route,
    Outlet,
} from 'react-router-dom';
import { WhenAuthenticated, WhenNoAuthenticated } from '@rainbow-modules/auth';
import usePageViewAnalytics from 'analytics/usePageViewAnalytics';
import AskAdditionalInformation from 'pages/AskAdditionalInformation';
import AskLanguage from 'pages/AskLanguage';
import Login from './pages/Login';
import Landing from './pages/Landing';
import AskName from './pages/AskName';
import AskAge from './pages/AskAge';
import AskGender from './pages/AskGender';
import Checkout from './pages/Checkout';
import PickChild from './pages/PickChild';

const App = () => {
    usePageViewAnalytics();
    return (
        <Routes>
            <Route
                path="/"
                element={(
                    <WhenNoAuthenticated redirect="/app">
                        <Login />
                    </WhenNoAuthenticated>
                )}
            />
            <Route
                path="/app"
                element={(
                    <WhenAuthenticated path="/" redirect="/">
                        <Outlet />
                    </WhenAuthenticated>
                )}
            >
                <Route path="" element={<Landing />} />
                <Route path="name" element={<AskName />} />
                <Route path="age" element={<AskAge />} />
                <Route path="gender" element={<AskGender />} />
                <Route path="language" element={<AskLanguage />} />
                <Route path="additional-information" element={<AskAdditionalInformation />} />
                <Route path="checkout" element={<Checkout />} />
                <Route path="pick-child" element={<PickChild />} />
            </Route>
        </Routes>
    );
};

export default App;
