import styled from 'styled-components';
import { Textarea } from 'react-rainbow-components';
import { GREY_LIGHTER, BRAND, BLACK } from '../../constants';

export default styled(Textarea)`
    label {
        font-size: 0.9375rem;
        font-weight: 500;
        margin-left: 2px;
        color: ${BLACK};
    }

    > div {
        border-radius: 0.375rem;
        border: none;
    }

    textarea {
        border-radius: 0.375rem;
        background-color: ${GREY_LIGHTER};
        padding: 1rem;

        ::placeholder {
            font-weight: 300 !important;
        }

        &:focus, &:active, &:visited {
            border: 1px solid ${BRAND} !important;
            background-color: #fff;
            box-shadow: rgb(225, 33, 35) 0px 0px 2px;
            padding: 0.875rem;
        }
    }
`;
