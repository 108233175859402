import { IconProps } from 'types';

const ChevronLeft = ({ className, title }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 22"
        width={22}
        height={22}
        fill="none"
    >
        <title>{title}</title>
        <path
            d="M13.75 5.5L8.25 11L13.75 16.5"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13.75 5.5L8.25 11L13.75 16.5"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default ChevronLeft;
