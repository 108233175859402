import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import logEvent from 'analytics/ga';

const usePageViewAnalytics = () => {
    const location = useLocation();
    const posthog = usePostHog();

    useEffect(() => {
        posthog?.capture('$pageview');
        logEvent('page_view');
    }, [location, posthog]);
};

export default usePageViewAnalytics;
