import { IconProps } from 'types';

const Plus = ({ title = 'Plus', className }: IconProps) => (
    <svg className={className} width={24} height={24} viewBox="0 0 24 24" fill="none">
        <title>{title}</title>
        <path d="M12 5V19" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5 12H19" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default Plus;
