import ButtonIcon from 'components/ButtonIcon';
import ChevronLeft from 'components/Icons/chevronLeft';
import { RenderIf } from 'react-rainbow-components';
import { Container, Name, StyledAppName } from './styled';

interface Props {
    onBack?: () => void;
    hideBackButton?: boolean;
}

const TopBar = ({ onBack, hideBackButton = false }: Props) => (
    <Container hideBackButton={hideBackButton}>
        <RenderIf isTrue={!hideBackButton}>
            <ButtonIcon
                size="small"
                borderRadius="semi-square"
                icon={<ChevronLeft />}
                onClick={onBack}
            />
        </RenderIf>
        <Name>
            <StyledAppName />
        </Name>
    </Container>
);

export default TopBar;
