import { useEffect, useState } from "react";
import { FirestoreError } from "firebase/firestore";
import { UseCountHook, ListServiceOpts, HookReturnCount } from "../../types";
import countFn from "./count";

export type ChildUseCountHook = (
  userId: string,
  opts?: ListServiceOpts
) => HookReturnCount;

const useCount: ChildUseCountHook = (userId, opts = {}) => {
  const [count, setCount] = useState<number | null>(null);
  const [error, setError] = useState<FirestoreError | null>(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const count = await countFn(userId, opts);
        setCount(count);
      } catch (err) {
        if (err instanceof FirestoreError) {
          setError(err);
        } else {
          setError({
            code: "unknown",
            message: "Unknown error",
            name: "Unknown error",
          });
        }
      }
      setLoading(false);
    })();
  }, []);

  return { count, error, isLoading };
};

export default useCount;
