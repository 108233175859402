import styled from 'styled-components';
import { BLACK } from '../../constants';

export const StyledFooter = styled.footer`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0 0.75rem 0;
    line-height: 1.467;
    font-size: 0.85rem;
    color: #6f6d7d;
    font-weight: 400;
`;

export const StyledLink = styled.a`
    line-height: 1.467;
    font-size: 0.85rem;
    color: #6f6d7d;
    font-weight: 400;
    margin-left: 0.5rem;
    border-bottom: 1px solid rgba(111, 109, 125, 0.75);

    &:hover {
        color: ${BLACK};
        text-decoration: none;
        border-bottom: 1px solid ${BLACK};
    }
`;
