import styled from 'styled-components';
import { BLACK } from '../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
`;

export const Title = styled.h1`
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 700;
    color: ${BLACK};
`;

export const Description = styled.p`
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    color: ${BLACK};
`;
