import { IconProps } from 'types';

const ArrowNarrowRight = ({ className, title }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={26}
        viewBox="0 0 25 26"
        fill="none"
    >
        <title>{title}</title>
        <path
            d="M5.50525 12.9998H19.5014"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15.5025 17.0801L19.5014 13"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15.5025 8.91992L19.5014 13"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />

    </svg>
);
export default ArrowNarrowRight;
