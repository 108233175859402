import styled from 'styled-components';
import Input from '../../components/Input';
import Button from '../../components/Button';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #fff;
    min-height: 100vh;

    @media (max-width: 800px) {
        height: 100%;
        min-height: 100%;
    }
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 460px;
    width: 100%;
    flex-grow: 1;
    padding: 1rem 2rem 2.5rem 2rem;
    gap: 40px;
`;

export const StyledInput = styled(Input)`
    width: 100%;
`;

export const StyledButton = styled(Button)`
    width: 100%;
    margin-bottom: 1.5rem;

    &[disabled] {
        box-shadow: none; !important;
    }
`;

export const ButtonLabel = styled.span`
    width: 100%;
    padding-left: 1.4rem;
`;

export const TopContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
