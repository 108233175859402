import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import { UniversalPickerOption } from '@rainbow-modules/forms';
import Plus from 'components/Icons/plus';
import useChildren from 'data/firestore/user/child/useCollection';
import useUpdateCustomer from 'data/hooks/useUpdateCustomer';
import { auth } from '../../firebase';
import TopBar from '../../components/TopBar';
import LegalFooter from '../../components/LegalFooter';
import Phone from '../../components/Icons/phone';
import PageHeader from '../../components/PageHeader';
import {
    Container,
    FormContainer,
    // StyledButton,
    ButtonLabel,
    StyledPickerOptionContent,
    StyledOptionLabel,
    StyledOptionDescription,
    StyledUniversalPicker,
    StyledRadioItem,
    StyledRadio,
    AddChildButton,
    StyledLinkButton,
    StyledContent,
    StyledSpinner,
} from './styled';

interface RadioItemProps {
    children: ReactNode;
    isHover: boolean;
    isFocused: boolean;
    isSelected: boolean;
    disabled: boolean;
}

const genderMap: Record<string, string> = {
    '': '',
    girl: 'Girl',
    boy: 'Boy',
};

const ageMap: Record<string, string> = {
    '': '',
    '2-8': '2-8 years old',
    '8-12': '8-12 years old',
    '12-18': '12-18 years old',
    'more-than-18': 'more than 18 years old',
};

const RadioItem = ({ children, ...rest }: RadioItemProps) => (
    <StyledRadioItem {...rest}>
        <StyledRadio {...rest} />
        <div>{children}</div>
    </StyledRadioItem>
);

const PickChild = () => {
    const navigate = useNavigate();
    const [value, setValue] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const user = auth.currentUser;
    const { data } = useChildren(user?.uid as string);
    const { mutateAsync: updateCustomer, isLoading } = useUpdateCustomer();

    useEffect(() => {
        if (Array.isArray(data) && data.length > 0) {
            setValue(data[0].id);
        }
    }, [data]);

    useEffect(() => {
        if (value) {
            (async () => {
                const child = data?.find((c) => c.id === value);
                if (!child || !user) return;

                try {
                    const { phoneNumber: number } = await updateCustomer({
                        body: {
                            phoneNumber: user.phoneNumber,
                            childId: child.id,
                        },
                    });
                    setPhoneNumber(number);
                } catch (error) {
                    //
                }
            })();
        }
    }, [data, updateCustomer, user, value]);

    return (
        <Container>
            <TopBar hideBackButton />
            <FormContainer>
                <PageHeader
                    title="Ready for a Jolly Call? 🎅🏻"
                    description="Here’s who Santa will be chatting with! You can add more children or start your call right away."
                />
                <StyledUniversalPicker
                    multiple={false}
                    direction="vertical"
                    value={value}
                    onChange={(v) => setValue(v as string)}
                >
                    {data?.map((child) => {
                        const gender = genderMap[child.gender || ''];
                        const age = ageMap[child.age || ''];
                        const separator = gender && age ? ', ' : '';
                        const description = `${gender}${separator}${age}`;

                        return (
                            <UniversalPickerOption
                                component={RadioItem}
                                name={child.id}
                                key={child.id}
                            >
                                <StyledPickerOptionContent>
                                    <StyledOptionLabel>{child.name}</StyledOptionLabel>
                                    <StyledOptionDescription>{description}</StyledOptionDescription>
                                </StyledPickerOptionContent>
                            </UniversalPickerOption>
                        );
                    })}

                    <AddChildButton onClick={() => navigate('/app/name')}>
                        <Plus />
                        <StyledOptionLabel>Add Another Child</StyledOptionLabel>
                    </AddChildButton>
                </StyledUniversalPicker>
                <StyledLinkButton
                    shaded
                    size="large"
                    variant="brand"
                    to={`tel:${phoneNumber}`}
                    disabled={isLoading}
                >
                    <RenderIf isTrue={isLoading}>
                        <StyledContent>
                            <Phone />
                            <ButtonLabel>
                                Call Santa Now!
                            </ButtonLabel>
                            <StyledSpinner
                                isVisible={isLoading}
                                variant="inverse"
                                size="small"
                            />
                        </StyledContent>
                    </RenderIf>
                    <RenderIf isTrue={!isLoading}>
                        <Phone />
                        <ButtonLabel>
                            Call Santa Now!
                        </ButtonLabel>
                    </RenderIf>
                </StyledLinkButton>
                {/* <StyledButton
                    borderRadius="semi-square"
                    size="large"
                    label="Sign Out"
                    onClick={() => auth.signOut()}
                /> */}
            </FormContainer>
            <LegalFooter />
        </Container>
    );
};

export default PickChild;
