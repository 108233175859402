const getFirebaseErrorMessage = (errorCode: string) => {
    switch (errorCode) {
        case 'auth/invalid-phone-number':
            return 'Invalid phone number.';
        case 'auth/missing-phone-number':
            return 'Please enter a phone number.';
        case 'auth/quota-exceeded':
            return 'SMS quota exceeded.';
        case 'auth/user-disabled':
            return 'User account has been disabled.';
        case 'auth/operation-not-allowed':
            return 'Phone number sign-in is disabled.';
        case 'auth/invalid-verification-code':
            return 'Invalid verification code.';
        case 'auth/invalid-verification-id':
            return 'Invalid verification ID.';
        default:
            return 'Something went wrong.';
    }
};

export default getFirebaseErrorMessage;
