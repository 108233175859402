import styled from 'styled-components';
import { CodeInput } from 'react-rainbow-components';
import { BRAND, GREY_LIGHTER, BLACK } from '../../constants';

export default styled(CodeInput)`
    width: 100%;
    display: flex;
    box-sizing: border-box;
    gap: 0.375rem;

    legend {
        font-size: 0.9375rem;
        font-weight: 500;
        padding-left: 0;
        color: ${BLACK};
    }

    input {
        border: 1px solid transparent;
        background-color: ${GREY_LIGHTER};
        border-radius: 0.375rem;
        padding: 0;
        margin: 0 0 6px;
        box-sizing: border-box;
        flex-grow: 1;
        width: inherit;

        &:focus, &:active {
            flex-grow: 1;
            width: inherit;
            padding: 0;
            border: 1px solid ${BRAND};
    }
`;
