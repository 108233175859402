import { ReactNode } from 'react';
import { Container, Title, Description } from './styled';

interface SummaryItemProps {
    title: ReactNode | string;
    description: ReactNode | string;
}

const PageHeader = ({
    title, description,
}: SummaryItemProps) => (
    <Container>
        <Title>
            {title}
        </Title>
        <Description>
            {description}
        </Description>
    </Container>
);

export default PageHeader;
